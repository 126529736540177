import { ProviderConfig as LaunchDarklyConfig } from 'launchdarkly-react-client-sdk';

import { LDFlagDefaults } from './flags';

export enum LaunchDarklyClientID {
  Dev0 = '6172e1599e07d425541262ef',
  Staging = '6172e12a9ab4512501338403',
  Prod = '616efd128d68b4252e2e5f14',
}

export const launchDarklyConfig: LaunchDarklyConfig = {
  clientSideID: '',
  deferInitialization: false,
  options: {
    bootstrap: 'localStorage',
  },
  reactOptions: {
    // If you change this, you need to update the `flagKeyMap` in LaunchDarklyMockProvider
    // see https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys for more details.
    useCamelCaseFlagKeys: false,
  },
  // LD will only subscribe to flags included in LDFlagDefaults
  flags: LDFlagDefaults,
};
