/*
 * This micro-app attempts to sync Embedded users
 * Amplitude account with that user's Studio account
 * by loading this small 'shim' from the Studio domain.
 * In the body of this file, we'll send a sync request
 * to Amplitude with the deviced_id from the Embedded
 * product.
 * Details and inspiration here:
 * //https://amplitude.com/docs/data/sources/instrument-track-unique-users#set-up-cross-domain-tracking
 */

import * as amplitude from '@amplitude/analytics-browser';

import {
  ApiKey as AmplitudeApiKey,
  Environment,
} from 'src/lib/analytics/amplitude/vendor';
import Config from 'src/lib/config';
type options = { environment: Environment };
const { amplitudeOptions } = Config.settings;

const { location } = document;
const { search } = location;
const searchParams = new URLSearchParams(search);
const PARAM_KEY = 'did';

const environment = (amplitudeOptions as options).environment;
const apiKey = AmplitudeApiKey[environment];

if (searchParams.has(PARAM_KEY)) {
  const embeddedAmplitudeDeviceId = searchParams.get(PARAM_KEY);

  if (embeddedAmplitudeDeviceId && apiKey) {
    amplitude.init(apiKey, undefined, {
      deviceId: embeddedAmplitudeDeviceId,
    });
  }
}
